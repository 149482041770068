import { PaymentMethod, TransactionStatus } from 'components/FilterModal/FilterHelper';
import NoData from 'components/NoData';
import Table from 'components/Table';
import TopBar from 'components/TopBar';
import TransactionModal from 'components/TransactionModal';
import Loading from 'components/UI/Loading';
import { useEffect, useState } from 'react';
import { Col, Container, Row } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';
import { getTransaction } from 'redux/actions/TransactionsAction';
import { columns } from 'utils/mockData';
import { buildTransactionTableData } from '../../utils/helper';
import './style.less';
import { useDebounce } from 'hooks/useDebounce';

const Transactions = () => {
  const [isOpen, setIsOpen] = useState(false);
  const [selectTransaction, setSelectTransaction] = useState(null);
  const dispatch = useDispatch();
  const [tableData, setTableData] = useState([]);
  const location = useLocation();
  const [search, setSearch] = useState('');
  const query = new URLSearchParams(location.search) || {};
  const payerCode = query.get('search');
  const [filterData, setFilterData] = useState([
    { title: 'Amount', type: 'slider' },
    ...TransactionStatus,
    ...PaymentMethod,
    //...CurrencyType,
  ]);
  const [isFillData, setIsFillData] = useState(false);
  const debouncedValue = useDebounce(search, 600);
  const {
    getTransaction: { data: { meta = {} } = {}, data = [], loading },
  } = useSelector(({ transaction }) => transaction);
  const { page, total, hasMore, perPage } = meta;
  const { transactions = [] } = data;
  const nextPage = page + 1;

  const toggleHandler = () => {
    setIsOpen(!isOpen);
  };

  const handleRowClick = (row) => {
    setSelectTransaction(row);
  };

  // Handle search change after debounce
  useEffect(() => {
    if (debouncedValue) {
      dispatch(
        getTransaction({
          search: debouncedValue,
        }),
      );
    }
    if (!debouncedValue) {
      dispatch(getTransaction());
    }
  }, [debouncedValue]);

  useEffect(() => {
    if (transactions.length > 0) {
      setTableData(buildTransactionTableData(transactions));

      const allTransactions = transactions.map(({ payer }) => {
        return {
          value: payer ? payer.code : null,
          label: payer ? `${payer.firstName} ${payer.lastName}` : 'N/A',
          isSelected: false,
        };
      });
      const uniqueArray = allTransactions.filter(
        (v, i, a) => a.findIndex((v2) => v2.value === v.value) === i,
      );
      setFilterData([
        ...filterData,
        { title: 'Budget', list: data },
        { title: 'Owner/holder', list: uniqueArray },
      ]);
    }
  }, [transactions]);

  useEffect(() => {
    dispatch(getTransaction());
  }, []);

  const handlePagination = (page) => {
    dispatch(getTransaction({ ...query, perPage, page }));
    // setFilteredQuery({ ...query, perPage, page });
  };

  const handleFilter = (query) => {
    dispatch(getTransaction(query));
  };

  if (loading) return <Loading isPage color="#D28B28" />;
  return (
    <div className="transactions-wrapper">
      <TopBar
        setSearchVal={setSearch}
        searchVal={search}
        showBarSearch
        headerText={'Transactions'}
        number={total}
        // showFilter
        addOnClick={toggleHandler}
        addIcon={false}
        inputPlaceholder="Search"
        filterData={filterData}
        handleFilterSelect={(updateVal) => {
          setFilterData(updateVal);
        }}
        handleFilterApply={handleFilter}
        withDate
      />
      {!tableData.length ? (
        <div className="innerWrapper">
          <NoData
            headerText="No transactions found"
            withButton={true}
            buttonLabel={'Clear filters'}
            onClickHandler={() => handleFilter()}
          />
        </div>
      ) : (
        <>
          <Container>
            <Row>
              <Col xs={12}>
                <Table
                  columns={columns}
                  data={buildTransactionTableData(transactions)}
                  pagination
                  onRowClick={handleRowClick}
                  hasMore={hasMore}
                  currentPage={page}
                  nextPage={() => handlePagination(nextPage)}
                  previousPage={() => handlePagination(page - 1)}
                  totalPage={Math.ceil(total / perPage)}
                />
              </Col>
            </Row>
          </Container>

          <TransactionModal
            selectTransaction={selectTransaction}
            setSelectTransaction={setSelectTransaction}
          />
        </>
      )}
    </div>
  );
};

export default Transactions;
